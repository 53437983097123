.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.btn {
  padding: 8px 16px;
  border-radius: 0;
  font-weight: 500;
}
.btn.btn-theme {
  background: #00728e;
  color: white;
}

.modal.product-modal .modal-header {
  border: 0;
  padding-bottom: 0;
}
.modal.product-modal .modal-body {
  padding-top: 0;
  padding-bottom: 32px;
  display: flex;
  align-items: flex-start;
}
.modal.product-modal .modal-body .img-outer {
  min-width: 300px;
  width: 300px;
  pointer-events: none;
  display: flex;
  align-items: center;
}
.modal.product-modal .modal-body .img-outer img {
  width: 300px;
  max-width: 100%;
}
.modal.product-modal .modal-body .details {
  width: 100%;
  border-left: 2px solid #dee2e6;
  padding-left: 40px;
  margin-left: 20px;
}
.modal.product-modal .modal-body .details .product-name {
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
}
.modal.product-modal .modal-body .details .product-price {
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  color: #00728e;
  margin-bottom: 16px;
}
.modal.product-modal .modal-body .details .product-price small {
  font-size: 20px;
  line-height: 24px;
}
.modal.product-modal .modal-body .details .sec-title {
  font-size: 18px;
  padding-bottom: 8px;
}
.modal.product-modal .modal-body .details .sec-title span {
  font-weight: bold;
}
.modal.product-modal .modal-body .details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.modal.product-modal .modal-body .details ul.size {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.modal.product-modal .modal-body .details ul.size li {
  border: 2px solid #00728e;
  padding: 0 8px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}
.modal.product-modal .modal-body .details ul.color {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.modal.product-modal .modal-body .details ul.color li {
  display: block;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #00728e;
  padding: 0 8px;
  text-transform: capitalize;
}
.modal.product-modal .modal-body .details ul.spec li {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  text-transform: capitalize;
}
.modal.product-modal .modal-body .details ul.spec li:last-child {
  margin-bottom: 0;
}
.modal.product-modal .modal-body .details ul.spec li span {
  font-weight: bold;
  min-width: 160px;
  display: inline-block;
}
@media (max-width: 768px) {
  .modal.product-modal .modal-body {
    align-items: center;
    flex-direction: column;
  }
  .modal.product-modal .modal-body .img-outer {
    min-width: auto;
    max-width: 200px;
    width: auto;
    padding-bottom: 20px;
  }
  .modal.product-modal .modal-body .img-outer img {
    width: auto;
    max-width: 100%;
  }
  .modal.product-modal .modal-body .details {
    border-left: 0;
    border-top: 2px solid #dee2e6;
    padding-left: 0;
    padding-top: 20px;
    margin-left: 0;
  }
  .modal.product-modal .modal-body .details .product-name {
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .modal.product-modal .modal-body .details .product-price {
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
    color: #00728e;
    margin-bottom: 16px;
  }
  .modal.product-modal .modal-body .details .sec-title {
    font-size: 18px;
    padding-bottom: 8px;
  }
  .modal.product-modal .modal-body .details .sec-title span {
    font-weight: bold;
  }
  .modal.product-modal .modal-body .details ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .modal.product-modal .modal-body .details ul.size {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
  }
  .modal.product-modal .modal-body .details ul.size li {
    border: 1px solid #00728e;
    padding: 0 8px;
  }
  .modal.product-modal .modal-body .details ul.color {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
  }
  .modal.product-modal .modal-body .details ul.color li {
    display: block;
  }
  .modal.product-modal .modal-body .details ul.spec li {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  .modal.product-modal .modal-body .details ul.spec li span {
    font-weight: bold;
    min-width: 160px;
    display: inline-block;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
    padding: 0 24px;
  }
}
.content-wrapper .header {
  position: absolute;
  top: 30px;
  left: 0;
  background: white;
  margin-left: 30px;
  width: calc(100% - 60px);
  border-radius: 8px;
  z-index: 999;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.2705882353);
}
.content-wrapper .header .navbar {
  padding: 0;
}
.content-wrapper .header .navbar .navbar-toggler {
  border-radius: 0;
  padding: 4px;
  outline: none;
  box-shadow: none;
}
.content-wrapper .header .navbar .navbar-toggler .navbar-toggler-icon {
  height: 24px;
  width: 24px;
}
.content-wrapper .header .navbar-brand {
  padding: 0;
}
.content-wrapper .header .navbar-brand .nav-link img {
  max-width: 90px;
}
.content-wrapper .header .navbar-nav .nav-link {
  padding: 0;
  margin: 0 24px 0 0;
  font-size: 18px;
  font-weight: 500;
  color: black;
}
.content-wrapper .header .navbar-nav .icons {
  border-left: 2px solid rgba(0, 0, 0, 0.5);
  padding-left: 24px;
  display: flex;
  align-items: center;
}
.content-wrapper .header .navbar-nav .icons a img {
  max-height: 20px;
}
.content-wrapper .header .navbar-nav .icons a:last-child {
  padding-left: 24px;
}
@media (max-width: 768px) {
  .content-wrapper .header {
    top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  .content-wrapper .header .navbar-nav {
    padding: 0 24px;
  }
  .content-wrapper .header .navbar-nav .nav-link {
    margin: 0 0px 24px 0;
  }
  .content-wrapper .header .navbar-nav .icons {
    border-left: 0 solid rgba(0, 0, 0, 0.5);
    padding-left: 0;
    padding-bottom: 24px;
  }
  .content-wrapper .header .container {
    padding: 0 16px;
  }
}
.content-wrapper .title-section {
  margin-bottom: 24px;
}
.content-wrapper .title-section .title {
  color: #00728e;
  font-size: 48px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .content-wrapper .title-section .title {
    font-size: 24px;
  }
}
.content-wrapper .banner-section {
  position: relative;
}
.content-wrapper .banner-section .banner-img {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.content-wrapper .banner-section .banner-details {
  position: absolute;
  left: 0;
  padding: 60px 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
  width: 100%;
}
.content-wrapper .banner-section .banner-details .title {
  font-size: 64px;
  color: white;
  margin-bottom: 16px;
}
.content-wrapper .banner-section .banner-details .title span {
  display: block;
}
.content-wrapper .banner-section .banner-details .title span.top {
  font-weight: 400;
  color: white;
}
.content-wrapper .banner-section .banner-details .title span.bottom {
  font-weight: 600;
}
.content-wrapper .banner-section .banner-details .subtitle {
  color: white;
  margin-bottom: 28px;
  max-width: 60%;
}
.content-wrapper .banner-section.small .banner-img {
  height: 80vh;
}
@media (max-width: 768px) {
  .content-wrapper .banner-section .banner-details {
    padding: 40px 0;
  }
  .content-wrapper .banner-section .banner-details .title {
    font-size: 32px;
    line-height: 36px;
  }
  .content-wrapper .banner-section .banner-details .subtitle {
    max-width: 100%;
  }
  .content-wrapper .banner-section.small .banner-img {
    height: 60vh;
  }
}
.content-wrapper .ourproducts-section {
  padding: 80px 0;
}
.content-wrapper .ourproducts-section .products-outer {
  display: flex;
  align-items: center;
  gap: 48px;
  padding-top: 32px;
}
.content-wrapper .ourproducts-section .products-outer .card-product {
  flex-grow: 1;
  flex-basis: 0;
  padding: 48px 0;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.content-wrapper .ourproducts-section .products-outer .card-product:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3019607843);
}
.content-wrapper .ourproducts-section .products-outer .card-product .img-outer {
  margin-bottom: 24px;
  text-align: center;
}
.content-wrapper .ourproducts-section .products-outer .card-product .img-outer img {
  max-width: 100%;
  max-height: 160px;
}
.content-wrapper .ourproducts-section .products-outer .card-product .title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: black;
}
@media (max-width: 768px) {
  .content-wrapper .ourproducts-section {
    padding: 40px 0;
  }
  .content-wrapper .ourproducts-section .products-outer {
    flex-direction: column;
    gap: 24px;
    padding-top: 20px;
  }
  .content-wrapper .ourproducts-section .products-outer .card-product {
    flex-basis: auto;
    padding: 24px 0;
    width: 100%;
  }
  .content-wrapper .ourproducts-section .products-outer .card-product .img-outer {
    margin-bottom: 12px;
  }
}
.content-wrapper .about-section {
  padding: 80px 0 80px 0;
  background: #f2f2f2;
}
.content-wrapper .about-section .sec-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 25px;
  font-weight: 500;
  color: #00728e;
}
.content-wrapper .about-section .sec-desc {
  margin: auto;
  text-align: center;
  max-width: 70%;
  font-size: 64px;
  line-height: 68px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .content-wrapper .about-section {
    padding: 40px 0 40px 0;
  }
  .content-wrapper .about-section .sec-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 18px;
  }
  .content-wrapper .about-section .sec-desc {
    max-width: 100%;
    font-size: 32px;
    line-height: 36px;
  }
}
.content-wrapper .brands-section {
  background: #11192c;
  padding: 80px 0;
}
.content-wrapper .brands-section .carousal-outer .swiper-slide {
  max-width: 260px;
  text-align: center;
}
.content-wrapper .brands-section .carousal-outer .slider-img {
  height: 120px;
  min-height: 120px;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 100%;
}
@media (max-width: 768px) {
  .content-wrapper .brands-section {
    padding: 40px 0;
  }
  .content-wrapper .brands-section .carousal-outer .swiper-slide {
    max-width: 180px;
  }
  .content-wrapper .brands-section .carousal-outer .slider-img {
    height: 60px;
    min-height: 60px;
  }
}
.content-wrapper .blockquote-section {
  padding: 80px 0;
}
.content-wrapper .blockquote-section .quote {
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .content-wrapper .blockquote-section {
    padding: 40px 0;
  }
  .content-wrapper .blockquote-section .quote {
    font-size: 18px;
  }
}
.content-wrapper .whatwedo-section {
  padding-bottom: 80px;
}
.content-wrapper .whatwedo-section .details {
  display: flex;
  align-items: center;
}
.content-wrapper .whatwedo-section .details > * {
  flex-grow: 1;
  flex-basis: 0;
}
.content-wrapper .whatwedo-section .details img {
  max-height: 400px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.content-wrapper .whatwedo-section .details .subtitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}
.content-wrapper .whatwedo-section .details p {
  margin-bottom: 30px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .content-wrapper .whatwedo-section {
    padding-bottom: 40px;
  }
  .content-wrapper .whatwedo-section .details {
    flex-direction: column-reverse;
  }
  .content-wrapper .whatwedo-section .details > * {
    width: 100%;
    flex-basis: initial;
  }
  .content-wrapper .whatwedo-section .details img {
    min-width: 100%;
    margin-bottom: 20px;
  }
  .content-wrapper .whatwedo-section .details .subtitle {
    margin-bottom: 8px;
  }
  .content-wrapper .whatwedo-section .details p {
    margin-bottom: 20px;
    font-weight: 500;
  }
}
.content-wrapper .filter-section {
  background: #00728e;
}
.content-wrapper .filter-section .form-check {
  padding: 0;
  margin: 0;
}
.content-wrapper .filter-section .form-check .form-check-input {
  display: none;
}
.content-wrapper .filter-section .form-check .form-check-input:checked + .form-check-label {
  color: white;
  background-color: #00728e;
  font-weight: bold;
}
.content-wrapper .filter-section .form-check .form-check-label {
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 8px 10px;
  margin: 0 20px 0 0;
  border-radius: 50px;
  cursor: pointer;
  border: 2px solid #00728e;
}
.content-wrapper .accordion.acc-parent {
  border: none;
  margin-bottom: 0;
}
.content-wrapper .accordion.acc-parent .accordion-item {
  border-radius: 0;
  border: none;
}
.content-wrapper .accordion.acc-parent .accordion-item .accordion-header .accordion-button {
  border: none;
  background: none;
  font-weight: bold;
  box-shadow: none;
  padding: 0 0 16px 0;
}
.content-wrapper .accordion.acc-parent .accordion-item .accordion-header .accordion-button::after {
  display: none;
}
.content-wrapper .accordion.acc-parent .accordion-item .accordion-header .accordion-button .filter-icon {
  max-height: 20px;
  padding-right: 4px;
}
@media (min-width: 993px) {
  .content-wrapper .accordion.acc-parent .accordion-item .accordion-collapse {
    display: block;
  }
}
.content-wrapper .accordion.acc-parent .accordion-item .accordion-body {
  padding: 0;
  border: none;
}
.content-wrapper .filter-sidebar .accordion {
  margin-bottom: 8px;
}
.content-wrapper .filter-sidebar .accordion .accordion-item {
  border-radius: 0;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-header {
  border: none;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-header .accordion-button {
  border-radius: 0;
  box-shadow: none;
  border: none;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  background: white;
  border: 1px solid #dee2e6;
  border-bottom: 4px solid #00728e;
  padding: 16px;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-header .accordion-button::after {
  display: block;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-collapse:not(.show) {
  display: none;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body {
  padding: 16px;
  border: 1px solid #dee2e6;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body ul {
  list-style: none;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body ul li:not(:last-child) {
  margin-bottom: 16px;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check {
  margin: 0;
  display: flex;
  align-items: center;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check .form-check-input {
  height: 20px;
  width: 20px;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check .form-check-input:checked {
  background-color: #00728e;
}
.content-wrapper .filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check .form-check-label {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.content-wrapper .product-listing {
  margin-top: 40px;
}
.content-wrapper .product-listing .form-search {
  border: 1px solid #dee2e6;
  border-bottom: 4px solid #00728e;
  border-radius: 0;
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 8px;
  outline: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.content-wrapper .product-listing .result {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  font-weight: 500;
}
.content-wrapper .product-listing .result span {
  font-weight: bold;
}
.content-wrapper .product-listing .product {
  margin-bottom: 60px;
  border-bottom: 5px solid #00728e;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  color: black;
  padding: 10px 10px 0 10px;
  text-align: center;
}
.content-wrapper .product-listing .product .card-body {
  text-decoration: none;
  display: block;
}
.content-wrapper .product-listing .product .img-outer {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  max-height: 250px;
  overflow: hidden;
}
.content-wrapper .product-listing .product .img-outer img {
  max-width: 100%;
  max-height: 100%;
}
.content-wrapper .product-listing .product .product-name {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}
.content-wrapper .product-listing .product .product-spec {
  font-size: 16px;
  font-weight: 500;
}
.content-wrapper .product-listing .product .product-price {
  font-size: 24px;
  color: #00728e;
  font-weight: bold;
  margin-bottom: 32px;
}
.content-wrapper .product-listing .product .product-price span {
  font-size: 16px;
  line-height: 20px;
}
@media (max-width: 768px) {
  .content-wrapper .product-listing .product {
    margin-bottom: 20px;
  }
}
.content-wrapper .footer {
  padding: 80px 0;
}
.content-wrapper .footer .top-section .brand-section img {
  max-width: 200px;
}
.content-wrapper .footer .top-section .quick-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.content-wrapper .footer .top-section .quick-links .title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
}
.content-wrapper .footer .top-section .quick-links ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.content-wrapper .footer .top-section .quick-links ul li a {
  font-size: 16px;
  color: black;
  text-decoration: none;
  font-weight: 500;
}
.content-wrapper .footer .top-section .quick-links ul li.social {
  display: flex;
  gap: 20px;
}
.content-wrapper .footer .top-section .quick-links ul li.social a {
  display: flex;
  align-items: center;
}
.content-wrapper .footer .top-section .quick-links ul li.social a img {
  height: 26px;
  padding-right: 4px;
}
.content-wrapper .footer .bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-wrapper .footer .bottom-section p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
.content-wrapper .footer .bottom-section a {
  text-decoration: none;
  color: #00728e;
}
@media (max-width: 1024px) {
  .content-wrapper .footer {
    padding: 40px 0;
  }
  .content-wrapper .footer .top-section {
    text-align: center;
  }
  .content-wrapper .footer .top-section .brand-section {
    text-align: center;
  }
  .content-wrapper .footer .top-section .brand-section img {
    max-width: 160px;
  }
  .content-wrapper .footer .top-section .quick-links {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    gap: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .content-wrapper .footer .top-section .quick-links .title {
    margin-bottom: 12px;
  }
  .content-wrapper .footer .top-section .quick-links ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .content-wrapper .footer .top-section .quick-links ul li.social {
    width: 100%;
    justify-content: center;
  }
}
.content-wrapper .stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.content-wrapper .stretch-card > .product {
  width: 100%;
  min-width: 100%;
}/*# sourceMappingURL=style.css.map */