.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

$theme: #00728e;
$secondary: #8cd7f6;

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.btn {
    padding: 8px 16px;
    border-radius: 0;
    font-weight: 500;

    &.btn-theme {
        background: $theme;
        color: white;
    }
}

.modal {
    &.product-modal {
        .modal-header {
            border: 0;
            padding-bottom: 0;
        }

        .modal-body {
            padding-top: 0;
            padding-bottom: 32px;
            display: flex;
            align-items: flex-start;

            .img-outer {
                min-width: 300px;
                width: 300px;
                pointer-events: none;
                display: flex;
                align-items: center;

                img {
                    width: 300px;
                    max-width: 100%;
                }
            }

            .details {
                width: 100%;
                border-left: 2px solid #dee2e6;
                padding-left: 40px;
                margin-left: 20px;

                .product-name {
                    font-size: 24px;
                    font-weight: bold;
                    text-transform: capitalize;
                }

                .product-price {
                    font-size: 32px;
                    line-height: 36px;
                    font-weight: bold;
                    color: $theme;
                    margin-bottom: 16px;

                    small {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }

                .sec-title {
                    font-size: 18px;
                    padding-bottom: 8px;

                    span {
                        font-weight: bold;
                    }

                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    &.size {
                        display: flex;
                        gap: 8px;
                        margin-bottom: 12px;

                        li {
                            border: 2px solid $theme;
                            padding: 0 8px;
                            font-weight: 500;
                            text-transform: capitalize;
                            cursor: pointer;
                        }
                    }

                    &.color {
                        display: flex;
                        gap: 8px;
                        margin-bottom: 12px;

                        li {
                            display: block;
                            font-size: 16px;
                            font-weight: 500;
                            border: 2px solid $theme;
                            padding: 0 8px;
                            text-transform: capitalize;
                        }
                    }

                    &.spec {
                        li {
                            font-size: 14px;
                            margin-bottom: 8px;
                            font-weight: 500;
                            text-transform: capitalize;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            span {
                                font-weight: bold;
                                min-width: 160px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        @media(max-width:768px) {
            .modal-body {
                align-items: center;
                flex-direction: column;

                .img-outer {
                    min-width: auto;
                    max-width: 200px;
                    width: auto;
                    padding-bottom: 20px;

                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }

                .details {
                    border-left: 0;
                    border-top: 2px solid #dee2e6;
                    padding-left: 0;
                    padding-top: 20px;
                    margin-left: 0;

                    .product-name {
                        font-size: 24px;
                        font-weight: bold;
                        text-transform: capitalize;
                    }

                    .product-price {
                        font-size: 32px;
                        line-height: 36px;
                        font-weight: bold;
                        color: $theme;
                        margin-bottom: 16px;
                    }

                    .sec-title {
                        font-size: 18px;
                        padding-bottom: 8px;

                        span {
                            font-weight: bold;
                        }

                    }

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        &.size {
                            display: flex;
                            gap: 8px;
                            margin-bottom: 12px;

                            li {
                                border: 1px solid $theme;
                                padding: 0 8px;
                            }
                        }

                        &.color {
                            display: flex;
                            gap: 8px;
                            margin-bottom: 12px;

                            li {
                                // background: black;
                                // height: 28px;
                                // width: 28px;
                                // border-radius: 50%;
                                display: block;
                            }
                        }

                        &.spec {
                            li {
                                font-size: 14px;
                                margin-bottom: 8px;
                                font-weight: 500;

                                span {
                                    font-weight: bold;
                                    min-width: 160px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:992px) {
    .container {
        max-width: 100%;
        padding: 0 24px;
    }
}

.content-wrapper {
    .header {
        position: absolute;
        top: 30px;
        left: 0;
        background: white;
        margin-left: 30px;
        width: calc(100% - 60px);
        border-radius: 8px;
        z-index: 999;
        box-shadow: 0px 9px 17px 0px #00000045;

        .navbar {
            padding: 0;

            .navbar-toggler {
                border-radius: 0;
                padding: 4px;
                outline: none;
                box-shadow: none;

                .navbar-toggler-icon {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .navbar-brand {
            padding: 0;

            .nav-link {
                img {
                    max-width: 90px;
                }
            }
        }

        .navbar-nav {
            .nav-link {
                padding: 0;
                margin: 0 24px 0 0;
                font-size: 18px;
                font-weight: 500;
                color: black;
            }

            .icons {
                border-left: 2px solid rgba(0, 0, 0, 0.5);
                padding-left: 24px;
                // padding-right: 12px;
                display: flex;
                align-items: center;

                a {
                    img {
                        max-height: 20px;
                    }

                    &:last-child {
                        padding-left: 24px;
                    }
                }
            }
        }

        @media(max-width:768px) {
            top: 20px;
            margin-left: 20px;
            width: calc(100% - 40px);

            .navbar-nav {
                padding: 0 24px;

                .nav-link {
                    margin: 0 0px 24px 0;
                }

                .icons {
                    border-left: 0 solid rgba(0, 0, 0, 0.5);
                    padding-left: 0;
                    padding-bottom: 24px;

                }
            }
            .container {
                padding: 0 16px;
            }
        }
    }

    .title-section {
        margin-bottom: 24px;

        .title {
            color: $theme;
            font-size: 48px;
            font-weight: 500;
        }

        @media(max-width:768px) {
            .title {
                font-size: 24px;
            }
        }
    }

    .banner-section {
        position: relative;

        .banner-img {
            height: 100vh;
            width: 100%;
            object-fit: cover;
        }

        .banner-details {
            position: absolute;
            left: 0;
            padding: 60px 0;
            bottom: 0;
            background: linear-gradient(0deg, rgb(0 0 0 / 90%), transparent);
            width: 100%;

            .title {
                font-size: 64px;
                color: white;
                margin-bottom: 16px;

                span {
                    display: block;

                    &.top {
                        font-weight: 400;
                        color: white;
                    }

                    &.bottom {
                        font-weight: 600;
                    }
                }
            }

            .subtitle {
                color: white;
                margin-bottom: 28px;
                max-width: 60%;
            }
        }

        &.small {
            .banner-img {
                height: 80vh;
            }
        }

        @media(max-width:768px) {


            .banner-details {
                padding: 40px 0;

                .title {
                    font-size: 32px;
                    line-height: 36px;

                }

                .subtitle {
                    max-width: 100%;
                }
            }

            &.small {
                .banner-img {
                    height: 60vh;
                }
            }
        }

    }

    .ourproducts-section {
        padding: 80px 0;

        .products-outer {
            display: flex;
            align-items: center;
            gap: 48px;
            padding-top: 32px;

            .card-product {
                flex-grow: 1;
                flex-basis: 0;
                padding: 48px 0;
                transition: all .5s ease-in-out;
                text-decoration: none;

                &:hover {
                    box-shadow: 0px 0px 40px 0px #0000004d;
                }

                .img-outer {
                    margin-bottom: 24px;
                    text-align: center;

                    img {
                        max-width: 100%;
                        max-height: 160px;
                    }
                }

                .title {
                    font-size: 24px;
                    font-weight: 500;
                    text-align: center;
                    color: black;
                }
            }
        }

        @media(max-width:768px) {
            padding: 40px 0;

            .products-outer {
                flex-direction: column;
                gap: 24px;
                padding-top: 20px;

                .card-product {
                    flex-basis: auto;
                    padding: 24px 0;
                    // border: 2px solid $theme;
                    width: 100%;

                    .img-outer {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    .about-section {
        padding: 80px 0 80px 0;
        background: #f2f2f2;

        .sec-title {
            text-align: center;
            font-size: 32px;
            margin-bottom: 25px;
            font-weight: 500;
            color: $theme;
        }

        .sec-desc {
            margin: auto;
            text-align: center;
            max-width: 70%;
            font-size: 64px;
            line-height: 68px;
            font-weight: 600;
        }

        @media(max-width:768px) {
            padding: 40px 0 40px 0;

            .sec-title {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 18px;
            }

            .sec-desc {
                max-width: 100%;
                font-size: 32px;
                line-height: 36px;
            }
        }
    }

    .brands-section {
        background: #11192c;
        padding: 80px 0;

        .carousal-outer {
            .swiper-slide {
                max-width: 260px;
                text-align: center;
            }

            .slider-img {
                height: 120px;
                min-height: 120px;
                object-fit: contain;
                max-width: 100%;
            }
        }

        @media(max-width:768px) {
            padding: 40px 0;

            .carousal-outer {
                .swiper-slide {
                    max-width: 180px;
                }

                .slider-img {
                    height: 60px;
                    min-height: 60px;
                }
            }

        }
    }

    .blockquote-section {
        padding: 80px 0;

        .quote {
            font-size: 24px;
            font-weight: 500;
        }

        @media(max-width:768px) {
            padding: 40px 0;

            .quote {
                font-size: 18px;
            }
        }
    }

    .whatwedo-section {
        padding-bottom: 80px;

        .details {
            display: flex;
            align-items: center;

            >* {
                flex-grow: 1;
                flex-basis: 0;
            }

            img {
                max-height: 400px;
                width: 100%;
                object-fit: cover;
            }

            .subtitle {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 30px;
                font-weight: 500;
            }
        }

        @media(max-width:768px) {
            padding-bottom: 40px;

            .details {
                flex-direction: column-reverse;

                >* {
                    width: 100%;
                    flex-basis: initial;
                }

                img {
                    min-width: 100%;
                    margin-bottom: 20px;
                }

                .subtitle {
                    margin-bottom: 8px;
                }

                p {
                    margin-bottom: 20px;
                    font-weight: 500;
                }
            }
        }
    }

    .filter-section {
        // margin-top: 180px;
        // display: none;
        background: $theme;

        .form-check {
            padding: 0;
            margin: 0;

            .form-check-input {
                display: none;

                &:checked {
                    +.form-check-label {
                        // border-bottom: 5px solid $theme;
                        color: white;
                        background-color: $theme;
                        font-weight: bold;
                    }
                }
            }

            .form-check-label {
                font-size: 18px;
                font-weight: 500;
                color: white;
                padding: 8px 10px;
                margin: 0 20px 0 0;
                border-radius: 50px;
                cursor: pointer;
                border: 2px solid $theme;
            }
        }
    }

    .accordion {
        &.acc-parent {
            border: none;
            margin-bottom: 0;

            .accordion-item {
                border-radius: 0;
                border: none;

                .accordion-header {
                    .accordion-button {
                        border: none;
                        background: none;
                        font-weight: bold;
                        box-shadow: none;
                        padding: 0 0 16px 0;

                        &::after {
                            display: none;
                        }

                        .filter-icon {
                            max-height: 20px;
                            padding-right: 4px;
                        }
                    }
                }

                .accordion-collapse {
                    @media(min-width: 993px) {
                        display: block;
                    }
                }

                .accordion-body {
                    padding: 0;
                    border: none;
                }
            }
        }
    }


    .filter-sidebar {
        .accordion {
            margin-bottom: 8px;

            .accordion-item {
                border-radius: 0;

                .accordion-header {
                    border: none;

                    .accordion-button {
                        border-radius: 0;
                        box-shadow: none;
                        border: none;
                        // background: $theme;
                        // color: white;
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 500;
                        background: white;
                        border: 1px solid #dee2e6;
                        border-bottom: 4px solid $theme;
                        padding: 16px;

                        &::after {
                            display: block;
                        }

                    }
                }

                .accordion-collapse {
                    &:not(.show) {
                        display: none;
                    }
                }

                .accordion-body {
                    padding: 16px;
                    border: 1px solid #dee2e6;

                    ul {
                        list-style: none;

                        li {
                            &:not(:last-child) {
                                margin-bottom: 16px;
                            }

                            .form-check {
                                margin: 0;
                                display: flex;
                                align-items: center;

                                .form-check-input {
                                    height: 20px;
                                    width: 20px;
                                    outline: none;
                                    box-shadow: none;
                                    border-radius: 0;

                                    &:checked {
                                        background-color: $theme;
                                    }
                                }

                                .form-check-label {
                                    padding-left: 8px;
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .product-listing {
        margin-top: 40px;

        .form-search {
            border: 1px solid #dee2e6;
            border-bottom: 4px solid $theme;
            border-radius: 0;
            width: 100%;
            padding: 16px 20px;
            margin-bottom: 8px;
            outline: none;
            box-shadow: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
        }

        .result {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 16px;
            font-weight: 500;

            span {
                font-weight: bold;
            }
        }

        .product {
            margin-bottom: 60px;
            border-bottom: 5px solid $theme;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            color: black;
            padding: 10px 10px 0 10px;

            .card-body {
                text-decoration: none;
                display: block;
            }

            .img-outer {
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 250px;
                max-height: 250px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    // width: 273px;
                    max-height: 100%;
                    // object-fit: cover;
                }
            }

            text-align: center;

            .product-name {
                font-size: 18px;
                font-weight: bold;
                text-transform: capitalize;
            }

            .product-spec {
                font-size: 16px;
                font-weight: 500;
            }

            .product-price {
                font-size: 24px;
                color: $theme;
                font-weight: bold;
                margin-bottom: 32px;

                span {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        @media(max-width:768px) {
            .product {
                margin-bottom: 20px;
            }
        }
    }

    .footer {
        padding: 80px 0;

        .top-section {
            .brand-section {

                img {
                    max-width: 200px;
                }
            }

            .quick-links {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                gap: 40px;
                padding-bottom: 40px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.5);

                .title {
                    font-size: 24px;
                    font-weight: 500;
                    margin-bottom: 24px;
                }

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    li {
                        a {
                            font-size: 16px;
                            color: black;
                            text-decoration: none;
                            font-weight: 500;
                        }

                        &.social {
                            display: flex;
                            gap: 20px;

                            a {
                                display: flex;
                                align-items: center;

                                img {
                                    height: 26px;
                                    padding-right: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-weight: 500;
            }

            a {
                text-decoration: none;
                color: $theme;
            }
        }

        @media(max-width:1024px) {
            padding: 40px 0;

            .top-section {
                text-align: center;

                .brand-section {
                    text-align: center;

                    img {
                        max-width: 160px;
                    }
                }

                .quick-links {
                    flex-direction: column;
                    justify-content: center;
                    // align-items: center;
                    margin-bottom: 0px;
                    gap: 20px;
                    padding-bottom: 20px;
                    margin-bottom: 20px;

                    .title {
                        margin-bottom: 12px;
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        li {
                            &.social {
                                width: 100%;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .stretch-card {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        > .product {
            width: 100%;
            min-width: 100%;
        }
    }
}